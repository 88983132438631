import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { DataTable } from "components";
import { IUrlParams } from "types";
import { useProductsBySeason } from "api/products/useProductsBySeason";
import useProductColumns from "components/Table/useProductColumns";
import { useAppState } from "providers/AppProvider";

function ManageLink({ href }: { href: string }) {
  const { t } = useTranslation();
  return (
    <Link
      underline="hover"
      style={{ textTransform: "uppercase", marginLeft: "auto" }}
      component="a"
      href={href}
    >
      {t("inventory.products.manage")}
    </Link>
  );
}

export function ProductsView() {
  const { configQuery, rootUrl, org, season } = useOrgState();
  const { userRole } = useAppState();
  const productsQ = useProductsBySeason({ org, seasonId: season?.id });
  const { t } = useTranslation();
  const { type } = useParams<IUrlParams>();
  const { setColSizes, hideMap, showMap, setTitle } = useInventoryDispatch();

  // org:user no access to portal
  const manageUrl =
    userRole !== "org:user"
      ? configQuery.data?.portal?.baseUrl && configQuery.data?.portal?.products
        ? `${configQuery.data.portal.baseUrl}${configQuery.data?.portal?.products}`
        : null
      : null;

  useEffect(() => {
    setColSizes([12, 0]);
    setTitle(t("inventory.products.title"));
  }, [setColSizes, setTitle, t]);

  useEffect(() => {
    hideMap();
    return showMap;
  }, [hideMap, showMap]);
  const { seedColumns, applicationColumns } = useProductColumns();

  return (
    <Paper sx={{ height: "100%" }}>
      {["seed", "application"].includes(type) ? (
        <TabContext value={type}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              ".filter-container": {
                marginLeft: "1rem",
              },
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                sx={{
                  ".MuiTabPanel-root": {
                    p: 0,
                  },
                  a: {
                    textDecoration: "none",
                  },
                }}
                onChange={() => {}}
                aria-label="product type tabs"
              >
                <Tab
                  label={t("inventory.products.seed.title")}
                  value="seed"
                  href={`${rootUrl}/inventory/products/seed`}
                />
                <Tab
                  label={t("inventory.products.application.title")}
                  href={`${rootUrl}/inventory/products/application`}
                  value="application"
                />
              </TabList>
            </Box>
            <TabPanel value="seed" sx={{ height: "100%", overflow: "auto" }}>
              <DataTable
                tableProps={{
                  size: undefined,
                }}
                components={{
                  HeaderLeft: (
                    <>
                      {manageUrl ? (
                        <ManageLink href={`${manageUrl}/seed`} />
                      ) : null}
                    </>
                  ),
                }}
                isLoading={productsQ.isLoading}
                data={productsQ?.data?.filter((d) => {
                  return d.isSeed;
                })}
                columns={seedColumns}
              />
            </TabPanel>
            <TabPanel
              value="application"
              sx={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <DataTable
                tableProps={{
                  size: undefined,
                }}
                components={{
                  HeaderLeft: (
                    <>
                      {manageUrl ? (
                        <ManageLink href={`${manageUrl}/application`} />
                      ) : null}
                    </>
                  ),
                }}
                isLoading={productsQ.isLoading}
                data={productsQ?.data?.filter((d) => {
                  return !d.isSeed;
                })}
                columns={applicationColumns}
              />
            </TabPanel>
          </Box>
        </TabContext>
      ) : (
        <Box
          sx={{ mt: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" component={"p"}>
            Not found
          </Typography>
          <Link href={`${rootUrl}/inventory/products/seed`}>Back</Link>
        </Box>
      )}
    </Paper>
  );
}
