import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";

export default function TableLoadingRow() {
  // small delay to prevent flash of loading view
  const [delay, setDelay] = useState(true);

  // TODO: allow passing in custom delay time to datatable component
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelay(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (delay) {
    return null;
  }
  return (
    <tr>
      <td className="text-center p-0" colSpan={100}>
        <LinearProgress />
      </td>
    </tr>
  );
}
