import LinearProgress from "@mui/material/LinearProgress";
import React, { useState, useEffect } from "react";

export default function AppLoading({ message }: { message?: string }) {
  // small delay to prevent flash of loading view
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelay(false);
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (delay) {
    return null;
  }
  return (
    <div className="text-center">
      <LinearProgress
        style={{ top: 0, left: 0, right: 0, position: "absolute" }}
      />
      <div>{message} </div>
    </div>
  );
}
