import { useTranslation } from "react-i18next";
import { Grid } from "components/layout/Grid";

export function FeatureLockedView({ title }: { title: string }) {
  const { t } = useTranslation();
  return (
    <>
      {title ? <h2>{title}</h2> : null}
      <Grid container>
        <Grid>
          <p className="text-uppercase mt-3">{t("common.featureLocked")}</p>
        </Grid>
      </Grid>
    </>
  );
}
