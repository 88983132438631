import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useOrgState } from "providers/OrgProvider";
import FieldsSummaryView from "../InventoryView/Fields/FieldsSummaryView";
import OperationsOverview from "./OperationsOverview";
import AssetsOverview from "./AssetsOverview";
import DashboardMapView from "./DashboardMapView";
import { useURLSearchParams, useSetURLSearchParams } from "components";
import { Grid } from "components/layout/Grid";

export default function DashboardView() {
  const { darkOrgLogoState, lightOrgLogoState } = useOrgState();
  const theme = useTheme();
  const { t } = useTranslation();
  const query = useURLSearchParams();
  const type = query.get("operationType") || "planted";
  const setQuery = useSetURLSearchParams();
  const [operationType, setOperationType] = useState(type);

  useEffect(() => {
    setQuery([["operationType", operationType]]);
  }, [operationType, type, setQuery]);

  return (
    <>
      <h2 aria-label={t("dashboard.title")} className="mb-0">
        <div className="d-flex align-items-center mb-2 overflow-hidden">
          <img
            alt={"Org logo"}
            // height to match the other pages text headers
            style={{ height: "30px" }}
            src={
              theme.palette.mode === "light"
                ? lightOrgLogoState.data?.src
                : darkOrgLogoState.data?.src
            }
          />
        </div>
      </h2>
      <Grid sx={{ pb: 3 }} height="100%" container overflow={"auto"}>
        <Grid height={"100%"} xs={12} lg={4}>
          <Paper
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FieldsSummaryView />
            <Box style={{ minHeight: 300, flexGrow: 1 }}>
              <DashboardMapView />
            </Box>
          </Paper>
        </Grid>
        <Grid
          sx={(theme) => ({
            [theme.breakpoints.up("lg")]: {
              height: "100%",
            },
          })}
          overflow={"auto"}
          xs={12}
          lg={8}
        >
          <Stack style={{ overflow: "auto", height: "100%" }} spacing={2}>
            <OperationsOverview
              operationType={operationType}
              setOperationType={setOperationType}
            />
            <Paper sx={{ flex: 1, p: 3 }}>
              <AssetsOverview />
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
