import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsState, useAssetsDispatch } from "providers/AssetsProvider";
import { usePrevious, sortObjArrByFields } from "components";
import { useTranslation } from "react-i18next";
import { List } from "react-feather";
import {
  getDefaultExpandedRows,
  parseFieldsFromData,
  getAssetIcon,
  parseSubtypesFromData,
} from "../utils";
import ReportView from "../ReportView";
import ReportFieldsSelect from "../ReportFieldsSelect";
import RecordsReport from "./RecordsReport";
import { useAssetReportColumns } from "./useAssetReportColumns";
import { useAssetsSeasonSummary } from "api/assets/useAssetSummaries";
import { Grid } from "components/layout/Grid";

export default function AssetsReports({ setAssetType }) {
  const { t } = useTranslation();
  const { org, rootUrl, orgData, season, fields } = useOrgState();
  const { reportType } = useParams();
  const prevFields = usePrevious(fields);
  const prevReportType = usePrevious(reportType);
  const { listBySeasonState } = useAssetsState();
  const { fetchListBySeason } = useAssetsDispatch();
  const prevAssets = usePrevious(listBySeasonState?.data);
  const [expanded, setExpanded] = useState();
  const [filteredFields, setFilteredFields] = useState();
  const [subtypes, setSubtypes] = useState();
  const [subtypeItem, setSubtypeItem] = useState();
  const [selectedAssets, setSelectedAssets] = useState(listBySeasonState?.data);
  const { plantCols, animalCols, getFixedCols } = useAssetReportColumns();
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, season?.id);
  const currentAssetType = assetsBySeasonQuery?.data?.find(
    (o) => o.assetEn?.toLowerCase() === reportType
  );

  const getAssetReportCols = useCallback(
    (activeType) => {
      const cols = [
        {
          id: "records",
          Header: "Records",
          accessor: function RecordAccessor(row) {
            return (
              <IconButton
                color="inherit"
                sx={{ margin: "0 auto" }}
                size="sm"
                href={`${rootUrl}/insights/reports/assets/${activeType}/${row.id}`}
              >
                <List size={18} />
              </IconButton>
            );
          },
        },
      ];
      if (activeType?.toLowerCase() === "plant") {
        return plantCols.concat(cols);
      }
      if (activeType?.toLowerCase() === "fixed") {
        const colsByGeom = getFixedCols(subtypeItem?.geometryType);
        return colsByGeom ? colsByGeom.concat(cols) : cols;
      }
      if (activeType?.toLowerCase() === "animal") {
        return animalCols.concat(cols);
      }
      return null;
    },
    [animalCols, plantCols, subtypeItem, getFixedCols, rootUrl]
  );

  useEffect(() => {
    fetchListBySeason();
  }, [fetchListBySeason]);

  useEffect(() => {
    if (reportType !== prevReportType) {
      setSubtypeItem(null);
      setAssetType(reportType);
    }
  }, [reportType, prevReportType, setAssetType]);

  useEffect(() => {
    // parse fields list and subtype options from new assets
    if (listBySeasonState?.data !== prevAssets || fields !== prevFields) {
      const newFields = parseFieldsFromData(listBySeasonState?.data, fields);
      const assetSubtypes = parseSubtypesFromData(listBySeasonState?.data);
      setSubtypes(assetSubtypes);
      const sorted = sortObjArrByFields(newFields, "farm", "field");
      setFilteredFields(sorted);
    }
  }, [listBySeasonState?.data, prevAssets, reportType, fields, prevFields]);

  useEffect(() => {
    setExpanded(getDefaultExpandedRows(selectedAssets));
  }, [selectedAssets]);

  return (
    <Grid height="100%" container className="cai-reports">
      <Grid
        display="flex"
        flexDirection={"column"}
        lg={6}
        xl={5}
        className="hide-print"
      >
        <Paper className="overflow-auto position-relative">
          {assetsBySeasonQuery.isLoading ? (
            <LinearProgress
              style={{ top: 0, left: 0, right: 0, position: "absolute" }}
            />
          ) : null}
          <Stack spacing={2} sx={{ p: 2 }}>
            <Typography component="h3" variant="h6">
              {t("insights.selectReportType")}
            </Typography>
            <Box className="d-flex select-reports-container justify-content-md-center">
              <Stack spacing={2}>
                <div className="d-flex select-reports-container justify-content-md-center">
                  <Stack spacing={2} direction="row">
                    {assetsBySeasonQuery?.data?.map((i) => {
                      const asset = i.assetEn?.toLowerCase();
                      const Icon = getAssetIcon(asset);
                      return (
                        <Stack
                          spacing={1}
                          key={asset}
                          sx={{ alignItems: "center" }}
                        >
                          <Box>
                            <IconButton
                              size="large"
                              sx={{
                                border: `${
                                  reportType === asset
                                    ? "solid 1px"
                                    : "solid 1px transparent"
                                }`,
                                svg: {
                                  fill: "inherit",
                                },
                                backgroundColor: i.color,
                                "&:hover": {
                                  backgroundColor: `${i.color}80`,
                                },
                              }}
                              href={`${rootUrl}/insights/reports/assets/${asset}`}
                            >
                              {Icon ? <Icon /> : null}
                            </IconButton>
                          </Box>
                          <Typography
                            sx={{ textTransform: "uppercase" }}
                            variant="body2"
                          >
                            {i.asset}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </div>
              </Stack>
            </Box>
          </Stack>
        </Paper>

        <Paper className="mt-3" style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            subtypes={subtypes}
            isLoading={
              assetsBySeasonQuery?.isLoading || listBySeasonState?.isLoading
            }
            fields={filteredFields}
            onSubtypeChange={(subtype) => {
              setSubtypeItem(subtype);
            }}
            onChange={(data) => {
              const selectedFields =
                filteredFields?.filter((co) => {
                  return data.find((d) => d.fieldId === co.fieldId);
                }) || [];
              // loop selected fields and get their children
              // (filtered by subtype, if selected)
              const filteredAssets = selectedFields.reduce((curr, item) => {
                let filteredChildren = item.children;
                if (subtypeItem) {
                  filteredChildren = item.children?.filter((c) => {
                    return c.subtypeId === subtypeItem.value;
                  });
                }
                return curr.concat(filteredChildren || []);
              }, []);
              setSelectedAssets(filteredAssets);
            }}
          />
        </Paper>
      </Grid>
      <Grid
        height="100%"
        display="flex"
        flexDirection={"column"}
        lg={6}
        xl={7}
        className="print-full-width"
      >
        <Route path="/:org/:season/insights/reports/assets/:reportType?" exact>
          <ReportView
            org={orgData?.name}
            season={season}
            color={currentAssetType?.color}
            titleIcon={getAssetIcon(currentAssetType?.assetEn?.toLowerCase())}
            title={
              currentAssetType
                ? `${currentAssetType.asset} ${t("insights.report")}`
                : ""
            }
            columns={getAssetReportCols(
              currentAssetType?.assetEn?.toLowerCase()
            )}
            data={selectedAssets}
            groupBy={["fieldFarmGroupKey"]}
            expanded={expanded}
            sortBy={[
              {
                id: "name",
                desc: false,
              },
            ]}
          />
        </Route>
        <Route
          path="/:org/:season/insights/reports/assets/:reportType/:assetId"
          exact
        >
          <RecordsReport
            currentAssetType={currentAssetType}
            currentAssets={listBySeasonState?.data}
          />
        </Route>
      </Grid>
    </Grid>
  );
}

AssetsReports.defaultProps = {};

AssetsReports.propTypes = {
  setAssetType: PropTypes.func.isRequired,
};
