import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Grid } from "components/layout/Grid";
import { useAssetsSeasonSummary } from "api/assets/useAssetSummaries";
import AssetsOverviewChart from "./AssetOverviewChart";
import { useOrgState } from "providers";

const skeletons = [2, 3, 4].map((i) => i * Math.random() * 100 + 75 / 10);

export default function AssetsOverview() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, season?.id);
  const data = assetsBySeasonQuery.data?.filter((d) => d.count);

  return (
    <>
      <h3>
        {t("inventory.assets.title")} {t("inventory.title")}
      </h3>
      {assetsBySeasonQuery.isLoading ? (
        <Stack>
          {[2, 3, 4].map((i) => {
            return (
              <Grid key={i}>
                <Skeleton
                  sx={{
                    transform: "none",
                    width: skeletons[i],
                    height: 25,
                  }}
                />
              </Grid>
            );
          })}
        </Stack>
      ) : null}
      {!assetsBySeasonQuery.isLoading && data?.length ? (
        <AssetsOverviewChart data={data} />
      ) : null}
      {!assetsBySeasonQuery.isLoading && !data?.length ? (
        <p className="text-center">{t("common.noData")}</p>
      ) : null}
    </>
  );
}
