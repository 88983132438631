import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import {
  useFieldsDispatch,
  useFieldsState,
  useInventoryState,
  useInventoryDispatch,
  useOrgState,
} from "providers";
import { downloadFile, GeoDataTable } from "components";
import { IField, IDataTableCellProps } from "types";
import "./index.scss";
import useAreaColumn from "components/Table/useAreaColumn";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import { EditIconButton } from "components/EditIconButton";
import { useFields } from "api/fields/useFields";

export default function FieldListView({
  onToggle,
  toggled,
}: {
  onToggle?: () => void;
  toggled?: boolean;
}) {
  const { t } = useTranslation();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const { exportFieldState, fieldsGeodataState } = useFieldsState();
  const { exportField, resetExportFieldState } = useFieldsDispatch();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { org, season, rootUrl } = useOrgState();
  const fieldsQ = useFields({ org, seasonId: season?.id, withThemes: true });
  const [loadingCellId, setLoadingCellId] = useState<string | number>();
  const areaHeader = useAreaColumn();

  useEffect(() => {
    async function getData() {
      if (exportFieldState?.data) {
        try {
          downloadFile({
            data: exportFieldState?.data,
            filetype: "text/plain",
            filename: `${
              exportFieldState?.data?.features
                ? exportFieldState?.data?.features[0].properties.name
                : "Field export"
            }.geojson`,
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
    getData();
  }, [exportFieldState?.data]);

  useEffect(() => {
    return () => {
      resetExportFieldState();
    };
  }, [resetExportFieldState]);

  return (
    <>
      <Box sx={{ display: "flex", pb: 2, alignItems: "center" }}>
        <Typography component={"h3"} variant="h5">
          {t("inventory.fields.fieldList")}
        </Typography>

        <InventoryCollapseAddButtons
          toggleExpanded={onToggle}
          expanded={toggled}
          addUrl={`${rootUrl}/inventory/fields/add`}
        />
      </Box>
      <GeoDataTable
        components={{
          HeaderLeft: (
            <p className="small m-0">
              {fieldsQ.data && fieldsQ.data.length
                ? t("inventory.fields.selectFieldToZoom")
                : ""}
            </p>
          ),
        }}
        style={{ minHeight: "100%" }}
        isLoading={fieldsQ.isLoading}
        map={inventoryMap}
        ftrsClicked={ftrsClicked}
        featureCollection={fieldsGeodataState?.data}
        highlightByIds={highlightLayerFeatures}
        data={fieldsQ.data}
        hiddenColumns={["farm"]}
        columns={[
          {
            id: "field-table-actions",
            accessor: "id",
            styles: {
              width: 100,
            },
            Cell: function ActionCell({ value }: IDataTableCellProps<IField>) {
              const errMsg =
                value === loadingCellId && exportFieldState.isError
                  ? `Download failed: ${exportFieldState.errorMessage}`
                  : null;
              const isLoading =
                value === loadingCellId && exportFieldState?.isLoading;
              return (
                <Stack
                  mt={0.5}
                  ml={1}
                  alignItems="center"
                  spacing={1}
                  direction={"row"}
                  sx={{ height: "100%" }}
                >
                  <EditIconButton
                    size="small"
                    title={t("common.edit")}
                    href={`${rootUrl}/inventory/fields/${value}/edit`}
                  />
                  <IconButton
                    size="small"
                    sx={{ bgcolor: errMsg ? "error.main" : "" }}
                    role={errMsg ? "alert" : ""}
                    aria-label={errMsg || t("common.download")}
                    title={!errMsg ? t("common.download") : errMsg}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setLoadingCellId(value);
                      exportField(value);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : errMsg ? (
                      <InfoOutlinedIcon />
                    ) : (
                      <DownloadIcon />
                    )}
                  </IconButton>
                </Stack>
              );
            },
            isSortable: false,
            disableGlobalFilter: true,
          },
          {
            Header: t("common.field"),
            accessor: "name",
            Cell: function FieldCol({
              value,
              row,
            }: IDataTableCellProps<IField>) {
              const { farm } = row.original;
              return (
                <div className="float-left d-flex flex-column">
                  <span>{value}</span>
                  <small>{farm}</small>
                </div>
              );
            },
          },
          areaHeader,
          // search only column
          { accessor: "farm" },
        ]}
      />
    </>
  );
}
