import React from "react";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

export default function PrivacyAndTOS({
  className = "mt-3",
  linksOnly,
}: {
  className?: string;
  linksOnly?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const linkColor = theme.palette.mode === "dark" ? "warning.main" : "";
  return (
    <p style={{ marginTop: "auto" }} className={className}>
      {!linksOnly ? `${t("auth.bySigningInYouAgreeToOur")} ` : null}
      <Link
        underline="none"
        component="a"
        sx={{
          color: linkColor,
          "&:hover": {
            color: linkColor,
          },
        }}
        href="https://platform.cultivateagi.com/legal/terms-of-service.html"
      >
        {t("auth.termsOfService")}
      </Link>
      {` ${t("common.and")} `}{" "}
      <Link
        underline="none"
        component="a"
        sx={{
          color: linkColor,
          "&:hover": {
            color: linkColor,
          },
        }}
        href="https://platform.cultivateagi.com/legal/privacy-policy.html"
      >
        {t("auth.privacyPolicy")}
      </Link>
      {!linksOnly ? `.` : null}
    </p>
  );
}
